/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.navbar-top {
    align-items: center;
    justify-content: space-between;
    height: 50px;
    flex-shrink: 0;
    width: 100%;
    background-color: white;
    box-shadow: 2px 2px 2px lightgray;
    padding: 6px;
    z-index: 100;
}

.navbar-top-mobile {
    display: none;
}

.navbar-top-medium{
    display: none;
}

.navbar-large {
    position: relative;
    flex-shrink: 0;
    background-color: white;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    transition: ease-in-out;
    transition-duration: 350ms;
    gap: 8px;
    z-index: 100;
}

@media (min-width: 900px) and (max-width: 1500px) {
    .navbar-top-medium{
        display: flex;
    }
} 

@media (max-width: 900px) {
    .navbar-top-mobile{
        display: flex;
    }
}
@media (max-width: 1500px){
    .navbar-large{
        display: none;
    }
}

.navbar-large-transition {
    transform: translateX(-250px);
    margin-right: -250px;
}

.nav-bar-link {
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
}
.nav-bar-link-active {
    color: white;
    background-color: var(--bs-primary);
}
.nav-bar-link:hover {
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
}

.nav-bar-link-active:hover {
    color: white;
    background-color: var(--bs-primary);
}