/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.desktop-route-card-header{
    display: flex;

    @media (max-width: 1000px){
        display: none;
    }
}

.mobile-route-card-header{
    display: none;

    @media (max-width: 1000px){
        display: flex;
    }
}

@mixin route-header-mixin{
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: 1px solid gray;
    justify-content: space-between;
    font-size: 16px;
    height: 40px;

    @media (max-width: 1000px) {
        font-size: 14px;
    }
}

.route-card-header{
    @include route-header-mixin;
}

.route-card-header-secondary{
    @include route-header-mixin;
    justify-content: left;
    gap: 8px;
    
    @media (max-width: 1000px) {
        font-size: 13px;
    }
}

@mixin route-stop-mixin{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    height: 40px;
    font-weight: bold;

    @media (max-width: 1000px) {
        font-size: 13px;
    }
}

.route-stop-header{
    @include route-stop-mixin;
}

.route-card-time-column{
    flex: 6;
    @media (max-width: 1000px) {
        width: 70px;
        flex: unset;
    }
}

.route-card-icon-column{
    width: 30px;
    @media (max-width: 1000px) {
        width: 25px;
    }
}

.desktop-empty-stop-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid gray;
    min-height: 50px;

    @media (max-width: 1000px) {
        height: 176px;
    }
}