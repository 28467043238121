/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.my-company-tabs-desktop{
    display: flex;
    margin-bottom: 8px;

    @media (max-width: 800px){
        display: none;
    }
    
}

.my-company-tabs-tablet{
    display: none;

    @media (max-width: 800px){
        display: block;
    }
    
    @media (max-width: 400px){
        display: none;
    }
}

.my-company-tabs-mobile{
    display: none;

    @media (max-width: 400px){
        display: block;
    }
    
}