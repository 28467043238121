/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.desktop-scheduling-button-group{
    display: flex;

    @media(max-width: 1000px){
        display: none;
    }
}

.mobile-scheduling-button-group{
    display: none;

    @media(max-width: 1000px){
        display: flex;
    }
}

.route-card-stop-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid gray;
    min-height: 35px;
    font-weight: bold;
    font-size: 14px;

    @media (max-width: 420px){
        font-size: 12px;
    }
}

.route-card-stop-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid gray;
    min-height: 35px;
    font-weight: bold;
    font-size: 13px;

    @media (max-width: 420px){
        font-size: 11px;
    }

}
/* style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, border: '1px solid gray', minHeight: 35}}><h6 style={{margin: 0, fontSize: 13, fontWeight: 'bold'} */