/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.calendar-th {
    font-size: 12px;
    width: 14.3%;
    text-align: center;
}
.calendar-tr {
    cursor: pointer;
    
}
.calendar-tr-selected {
    background-color: #ef3c3975;
    
}
.calendar-td {
    font-size: 15px;
    text-align: center;
}

.calendar-td-off-month {
    opacity: 0.3;
}
.calendar-td-today {
    font-weight: bolder;
}