/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.desktop-route-editor-draggable-container{
    display: flex;
    @media (max-width: 1000px){
        display: none;
    }
}

.mobile-route-editor-draggable-container{
    display: none;

    @media (max-width: 1000px){
        display: flex;
        flex-direction: column;
    }
}

.expand-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 140px;
    transition: ease-out;
    transition-duration: 200ms;
    border: 3px solid lightgray;
}

.expand-box:hover{
    background-color: var(--bs-primary);
    border: 3px solid var(--bs-primary);

    .expand-icon{
        color: white;
    }
    .expand-text{
        color: white;
    }
}

.expand-text{
    color: black;
}
.expand-icon{
    color: var(--bs-primary);
}

.expand-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 20px 8px;
    position: relative;
    border: 3px solid lightgray;
    width: 330px;
    flex: 1;
    border-top-left-radius: 8px;
    border-Bottom-left-radius: 8px;
}
