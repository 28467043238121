/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.desktop-route-card-draggable-row{
    display: flex;

    @media (max-width: 1000px){
        display: none;   
    }

}

.mobile-route-card-draggable-row{
    display: none;

    @media (max-width: 1000px){
        display: flex;   
    }
}

.draggable-item{
    transition: ease-in-out;
    transition-duration: 100ms;
    background-color: white;
}

.draggable-item:hover{
    background-color: var(--bs-primary);
    color: white;
}

.draggable-stop-row{
    transition: ease-in-out;
    transition-duration: 100ms;
    background-color: white;
}

.draggable-stop-row:hover{
    background-color: rgb(247, 247, 247)
}

